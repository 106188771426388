.title1 {
  font-size: 53px;
  line-height: 1.16981132;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
  hyphens: auto;
}
.title1:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title2 {
  font-size: 35px;
  line-height: 1.17142857;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
}
.area--one .title2:after,
.area--intro .title2:after,
.section--teaserSlider .title2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title3 {
  font-size: 26px;
  line-height: 1.19230769;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.lead {
  font-size: 24px;
  line-height: 1.26086957;
  color: var(--s-textcolor);
  font-weight: 300;
}
@media (max-width: 1023px) {
  .lead {
    font-size: 22px;
    line-height: 1.18181818;
  }
}
.copy {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.smallText {
  font-size: 17px;
  line-height: 1.17647059;
}
.quote {
  font-family: 'Noto Serif', serif;
  font-size: 28px;
  line-height: 1.14285714;
  color: var(--s-textcolor);
  font-style: italic;
}
@media (max-width: 1023px) {
  .quote {
    font-size: 26px;
    line-height: 1.19230769;
  }
}
.footnote {
  font-size: 219px;
  line-height: 0.79908676;
  letter-spacing: 0.02em;
  color: var(--s-folding-number-color);
  font-family: 'Noto Serif', serif;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .footnote {
    font-size: 100px;
    line-height: 0.8;
  }
}
.moodSlogan {
  font-size: 53px;
  line-height: 1.16981132;
  color: #FFFFFF;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  text-align: right;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .moodSlogan {
    font-size: 22px;
    line-height: 1.08333333;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.button:before,
.button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.button:hover:after,
.button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.container--mood {
  width: calc(100% + 30px);
  margin-left: -30px;
}
.mood-background {
  width: 100vw;
}
.unit--blogImages {
  width: 50%;
  margin-left: -30px;
}
.unit--blogContent {
  width: 50%;
}
.footarea {
  grid-template-columns: repeat(6, 1fr);
}
.footpart--one {
  grid-column: 1 / span 3;
  margin-left: 30px;
  margin-right: 0;
}
.footpart--two {
  grid-column: 4 / span 3;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}
.area--one {
  grid-template-columns: repeat(6, 1fr);
}
.area--one .unitOne--1-1 {
  grid-column: span 6;
}
.area--one .unitOne--1-2 {
  grid-column: span 3;
}
.area--one .unitOne--1-3 {
  grid-column: span 3;
}
.area--one .unitOne .unit__body {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitOne .partWidth--1-1 {
  grid-column: span 12;
}
.area--one .unitOne .partWidth--1-2 {
  grid-column: span 6;
}
.area--one .unitOne .partWidth--1-4 {
  grid-column: span 3;
}
.area--one .unitTwo {
  grid-column: span 6;
}
.area--one .unitTwo .more,
.area--one .unitTwo .less {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--one .unitTwo .partWidth--1-1 {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.area--one .unitTwo .partWidth--1-2 {
  width: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.area--one .unitTwo .partWidth--1-4 {
  width: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.area--one .unitThree {
  grid-column: span 3;
}
.area--one .unitFour {
  grid-column: span 6;
}
.area--one .unitFour .unit__background {
  margin-right: -30px;
  width: 50%;
}
.area--one .unitFour .unit__content {
  width: 50%;
}
.area--one .unitFive {
  grid-column: span 3;
}
.area--one .unitFive .unit__body {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitFive .partWidth--1-1 {
  grid-column: span 12;
}
.area--one .unitFive .partWidth--1-2 {
  grid-column: span 8;
}
.area--one .unitFive .partWidth--1-4 {
  grid-column: span 4;
  max-width: 100%;
}
.area--one .unit--customerSlider {
  grid-column: span 6;
}
.area--intro .unit .unit__body {
  grid-template-columns: repeat(6, 1fr);
}
.area--intro .unit .partWidth--1-1 {
  grid-column: span 6;
}
.area--intro .unit .partWidth--1-2 {
  grid-column: span 3;
}
.area--intro .unit .partWidth--1-4 {
  grid-column: span 3;
}
/*# sourceMappingURL=./screen-medium.css.map */